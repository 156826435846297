import { isEqual } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import cloneDeep from 'helpers/cloneDeep';
import ComponentStore from 'components/data/ComponentStore';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { Brick, BrickType } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { BrickData } from '../types/brick.type';
import { MODEL_PUBLISH_METADATA } from '../constants';
import { MultiSocialMapping } from '../data/multiSocialMapping';

class MultiSocialHelpers {
    static handleOriginalBrick = (newActiveItem?: Brick) => {
        const originalBrick: Brick | undefined = cloneDeep(ComponentStore.get('Bricks').originalBrick);
        const modifiedBrick: Brick | undefined = cloneDeep(BrickHelpers.getBrickById(originalBrick?.id));

        if (originalBrick?.type === BrickType.MASTER_CHILD || originalBrick?.type === BrickType.MASTER || originalBrick?.subType?.startsWith('multiSocial_')) {
            if (!originalBrick || !modifiedBrick) {
                SnackbarUtils.error('Something went wrong while saving the multisocial bricks');
                return;
            }

            if (
                !isEqual(originalBrick?.data, modifiedBrick?.data) &&
                (originalBrick?.type === BrickType.MASTER_CHILD || originalBrick?.type === BrickType.MASTER)
            ) {
                this.handleSubItemDataChange(originalBrick, modifiedBrick);
            } else if (!isEqual(originalBrick?.data, modifiedBrick?.data) && originalBrick?.subType?.startsWith('multiSocial_')) {
                this.handleMasterBrickChange(modifiedBrick);
            }
        }

        // If there is oen, add the new originalBrick to the store
        if (newActiveItem?.type === BrickType.MASTER_CHILD || newActiveItem?.type === BrickType.MASTER || newActiveItem?.subType?.startsWith('multiSocial_')) {
            ComponentStore.setModel('Bricks', 'originalBrick', newActiveItem);
        }
    };

    /**
     * Looks for overwritten properties on subitems
     * @param originalBrick brick before modification
     * @param modifiedBrick modified brick
     */
    private static handleSubItemDataChange = (originalBrick: Brick, modifiedBrick: Brick) => {
        // Checks if brick is master item of a feed
        const parent = BrickHelpers.getBrickById(originalBrick.parentId);
        if (parent?.subType.startsWith('feed')) return;

        const originalBrickData = get(originalBrick, MODEL_PUBLISH_METADATA) as BrickData;
        const modifiedBrickData = get(modifiedBrick, MODEL_PUBLISH_METADATA) as BrickData;

        // Goes through each property and checks if it is modified
        Object.keys(modifiedBrickData).forEach((dataKey) => {
            if (!modifiedBrickData.overwrittenProperties) modifiedBrickData.overwrittenProperties = [];

            // If property is modified it goes to the array of the overwritten properties
            if (originalBrickData[dataKey] !== modifiedBrickData[dataKey] && !modifiedBrickData.overwrittenProperties.includes(dataKey))
                modifiedBrickData.overwrittenProperties.push(dataKey);
        });
        // Saves the subitem with the overwritten properties
        EditorData.setModel(`bricks.data.i_${modifiedBrick.id}.${MODEL_PUBLISH_METADATA}`, modifiedBrickData);
    };

    /**
     * Handles master brick change
     * @param modifiedBrick modified master brick
     */
    private static handleMasterBrickChange = (masterBrick: Brick) => {
        const masterBrickData = get(masterBrick, MODEL_PUBLISH_METADATA) as BrickData;
        const subItems = masterBrick.subItems as Brick[];

        if (!subItems || !subItems.length) return;

        //ComponentStoreHelpers.setModel('Bricks', `updatedBricks.${masterBrick.id}`, true);

        // Loops through the subitems of the master brick and modify its data
        const modifiedBricks = subItems.reduce((acc, currentBrick: Brick) => {
            const modifiedBrick = this.modifySubItem(currentBrick, masterBrickData);

            ComponentStoreHelpers.setModel('Bricks', `updatedBricks.${currentBrick.id}`, true);

            return { ...acc, [`i_${currentBrick.id}`]: { ...modifiedBrick } };
        }, {});

        const allBricks = EditorData.getValueFromModel('bricks.data');

        EditorData.setModel('bricks.data', { ...allBricks, ...modifiedBricks });
    };

    /**
     * Modifies subitem
     * @param subItem brick of the subitem
     * @param masterBrickData data of the master brick
     * @returns the modified subitem
     */
    private static modifySubItem = (subItem: Brick, masterBrickData: BrickData) => {
        const channel = subItem.subType?.split('_')[0] as string;
        const subItemData = get(subItem, MODEL_PUBLISH_METADATA, {});
        const overwrittenProperties = subItemData?.overwrittenProperties;
        // Loops through the master brick properties
        const newData = Object.keys(masterBrickData).reduce((acc, dataKey) => {
            const newValue = this.getSubItemData(masterBrickData, channel, dataKey, overwrittenProperties);

            // Creates the data object if does not exists
            if (acc === null && newValue && !isEmpty(newValue)) acc = {};

            // Loops through the new values and put it to the data object
            Object.keys(newValue).forEach((key) => {
                set(acc, key, newValue[key]);
            });

            return acc;
        }, subItemData);

        set(subItem, MODEL_PUBLISH_METADATA, newData);
        return subItem;
    };

    /**
     * Maps data to the values from the master brick
     * @param masterData data of the master brick
     * @param channel
     * @param dataKey unified property
     * @param overwrittenProperties of the subitem
     * @returns subItem data
     */
    private static getSubItemData = (masterData: any, channel: string, dataKey: string, overwrittenProperties: string[] | undefined) => {
        const propertySettings = MultiSocialMapping[dataKey];
        const masterValue = masterData[dataKey];
        const channelProperty = propertySettings.properties[channel];
        // Check if the channel property is overwritten
        if (!Array.isArray(channelProperty) && overwrittenProperties?.includes(channelProperty)) return {};

        // Makes an array of the master data
        if (!Array.isArray(channelProperty) && channelProperty && propertySettings.unifiedValues && propertySettings.type === 'array') {
            const unifiedValues = masterValue.reduce((acc, val) => {
                if (!propertySettings.unifiedValues) return acc;
                const value = propertySettings.unifiedValues[val][channel];
                return value ? [...acc, value] : acc;
            }, []);
            return { [channelProperty]: unifiedValues };
        }

        // Have unified values
        if (!Array.isArray(channelProperty) && propertySettings.unifiedValues && channelProperty) {
            const unifiedValue = propertySettings.unifiedValues[masterValue][channel];
            return { [channelProperty]: unifiedValue };
        }

        // Copies the data from the master property
        if (!Array.isArray(channelProperty) && channelProperty) {
            return { [channelProperty]: masterValue };
        }

        return {};
    };

    /**
     * Reset a specific property in a subItem brick. It removes the specific property from the overwrittenProperties and
     * runs the function again that maps the master brick to the subitems
     * @param brick The brick from which the property will be removed
     * @param property The property to remove
     */
    static resetSingleOverwrite = (brick: Brick, property: string) => {
        const { parent, brickInParentIndex } = this.getParentAndBrickInParentIndex(brick);

        if (parent === null || !parent.subItems || brickInParentIndex === null) return;

        // We delete the specific property from the overwrittenProperties and map it again with the value of the master brick
        let overwrittenProperties = parent?.subItems?.[brickInParentIndex].data?.publish?.data?.overwrittenProperties;
        overwrittenProperties = overwrittenProperties?.filter((overwrittenProperty) => overwrittenProperty !== property);

        if (!overwrittenProperties) return;

        if (overwrittenProperties.length === 0) {
            delete parent?.subItems?.[brickInParentIndex].data?.publish?.data?.overwrittenProperties;
        } else {
            set(parent, `subItems.${brickInParentIndex}.${MODEL_PUBLISH_METADATA}`, {
                ...parent.subItems[brickInParentIndex].data?.publish?.data,
                overwrittenProperties
            });
        }

        this.handleMasterBrickChange(parent);

        ComponentStore.setModel('Bricks', `updatedBricks.${brick.id}`, true);
    };

    /**
     * Resets all overwrites of a subItem brick. It removes the overwritten properties and runs the function again that maps the master brick to the subitems
     * @param brick The brick from which the properties will be removed
     */
    static resetAllOverwrites = (brick: Brick) => {
        const { parent, brickInParentIndex } = this.getParentAndBrickInParentIndex(brick);

        if (parent === null || brickInParentIndex === null) return;

        // We delete the overwrittenProperties and map all the values from the master brick again
        delete parent?.subItems?.[brickInParentIndex].data?.publish?.data?.overwrittenProperties;

        this.handleMasterBrickChange(parent);

        ComponentStore.setModel('Bricks', `updatedBricks.${brick.id}`, true);
    };

    /**
     * This functions gets the parent of the brick (so the master brick of the subitems) and the index of the brick in the list of subitems in the master brick
     * @param brick The brick from which the property/properties will be removed
     * @returns An object of the parent and the index of the brick in the parent. If one of those is missing, it returns an object where both of them are null
     */
    private static getParentAndBrickInParentIndex = (brick: Brick): { parent: Brick | null; brickInParentIndex: number | null } => {
        const parent = BrickHelpers.getBrickById(brick.parentId || '');

        if (!parent || !parent.subItems) return { parent: null, brickInParentIndex: null };

        // Find the index of the brick with overwrites in the parents subitems
        const brickInParentIndex = parent.subItems.findIndex((subItem) => subItem.id === brick.id);

        if (brickInParentIndex < 0) return { parent: null, brickInParentIndex: null };

        return { parent, brickInParentIndex };
    };
}

export default MultiSocialHelpers;
