import { useMemo } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { BricksComponentStore } from '../types/bricksComponentStore.type';
import { ValidationResult } from '../types/validator.type';

interface UseValidations {
    validations?: ValidationResult[];
    errors?: ValidationResult[];
    warnings?: ValidationResult[];
}

interface ComponentStoreProps {
    activeItemId: BricksComponentStore['activeItemId'];
    validationErrors: BricksComponentStore['validationErrors'];
}

/**
 * Hook to listen to a for updates of validations
 * @param brickId Optional brick id to listen on.
 * @returns an object of UseValidations
 */
const useValidations = (brickIds?: string[], tabIdentifier?: string, identifier?: string): UseValidations => {
    const { activeItemId, validationErrors } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: {
            activeItemId: 'activeItemId',
            validationErrors: 'validationErrors'
        }
    });

    const validations = useMemo(() => {
        let validationResultsCopy: ValidationResult[] = [];

        if (brickIds?.length) {
            brickIds?.forEach((brickId) => {
                if (validationErrors?.[brickId]) validationResultsCopy.push(...validationErrors[brickId]);
            });
        } else if (activeItemId && validationErrors?.[activeItemId]) validationResultsCopy = validationErrors?.[activeItemId];

        if (!validationResultsCopy?.length) return;

        if (tabIdentifier) validationResultsCopy = validationResultsCopy.filter((item) => item.tabKey === tabIdentifier);

        if (identifier) validationResultsCopy = validationResultsCopy.filter((item) => item.inputIdentifiers?.includes(identifier));

        return validationResultsCopy;
    }, [brickIds, activeItemId, validationErrors, identifier]);

    const errors = useMemo(() => {
        return validations?.filter((validation) => validation.severity === 'error');
    }, [validations]);

    const warnings = useMemo(() => {
        return validations?.filter((validation) => validation.severity === 'warning');
    }, [validations]);

    return { validations, errors, warnings };
};

export default useValidations;
