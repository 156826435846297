import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import EditorData from 'components/editor-data/EditorData';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import PublishHelpers from '../helpers/publish.helpers';
import { AssetRestrictions } from '../types/preset';
import { BrickPublishJobData } from '../hooks/useBricksPublish';

export interface PublishResponse {
    status: 'success' | 'error';
    publishJobs?: BrickPublishJobData[];
    publishId?: string;
    jobId?: string;
    message?: string;
    error?: string;
}

export interface ValidateAssetResponse {
    valid: boolean;
    errors: AssetRestrictions[];
    warnings: AssetRestrictions[];
}

export default class PublishService {
    static publishBricks = async (bricks: Brick[]): Promise<PublishResponse | undefined> => {
        const campaignId = EditorData.getId();

        // Get newest publish data of bricks
        const bricksToPublish = bricks.map((brick: Brick) => BrickHelpers.getBrickById(brick.id)).filter((brick) => brick !== undefined) as Brick[];

        // PublishHelpers.handleBrickPublishStatusUpdate(bricksToPublish, 'publishing');
        const publishId = uuidv4();

        try {
            const response = await axios.post(process.env.APP_V2_API_HOST + 'bricks/output/publish', bricksToPublish, {
                params: { campaignId, publishId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            if (response?.data?.jobId) {
                const jobId = response?.data?.jobId;
                const publishJob = PublishHelpers.handleAddBricksPublishJob(bricksToPublish, jobId, publishId);
                PublishHelpers.handleBrickPublishStatusUpdate(bricksToPublish);

                return {
                    status: 'success',
                    publishJobs: bricksToPublish.map((brick) => {
                        return {
                            brickId: brick.id,
                            ...publishJob
                        };
                    }),
                    message: 'Publish request successful.',
                    jobId,
                    publishId
                };
            }

            return {
                status: 'error',
                message: 'Publish request successful, but no jobId in the response.'
            };
        } catch (error: unknown) {
            // PublishHelpers.handleBrickPublishStatusUpdate(bricksToPublish, 'failed');

            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    /**
     * Downloads bricks
     * @param bricks
     * @returns publish job id
     */
    static downloadBricks = async (bricks: Brick[], archiveName?: string): Promise<PublishResponse | undefined> => {
        const campaignId = EditorData.getId();

        // Bricks require publish before download
        const publishBricks = bricks.filter((brick) => {
            const setup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
            return setup?.outputAction === 'publish' || setup?.outputAction === 'all';
        });

        const publishId = uuidv4();

        try {
            const response = await axios.post(
                process.env.APP_V2_API_HOST + 'bricks/output/download',
                { bricks, archiveName },
                {
                    params: { campaignId, publishId },
                    headers: { Authorization: `Bearer ${User.get('apiToken')}` }
                }
            );

            if (response?.data?.jobId) {
                const jobId = response?.data?.jobId;
                // Add publish bricks to the publish execution panel
                const publishJob = PublishHelpers.handleAddBricksPublishJob(publishBricks, jobId, publishId);
                PublishHelpers.handleBrickPublishStatusUpdate(publishBricks);

                return {
                    status: 'success',
                    publishJobs: publishBricks.map((brick) => {
                        return {
                            brickId: brick.id,
                            ...publishJob
                        };
                    }),
                    message: 'Publish request successful.',
                    jobId,
                    publishId
                };
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    /**
     * Publishes feed
     * @param brick feed
     * @returns job status
     */
    static publishFeed = async (brick: Brick): Promise<PublishResponse | undefined> => {
        const campaignId = EditorData.getId();

        PublishHelpers.handleBrickPublishStatusUpdate(brick);

        try {
            const response = await axios.post(process.env.APP_V2_API_HOST + 'bricks/output/publish/feed', {
                params: { campaignId, brickId: brick.id },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            if (response?.data?.jobId) {
                PublishHelpers.handleBrickPublishStatusUpdate(brick);

                return {
                    status: 'success',
                    // jobId: response.data.jobId,
                    message: 'Publish request successful.'
                };
            }

            PublishHelpers.handleBrickPublishStatusUpdate(brick);

            return {
                status: 'error',
                message: 'Publish request successful, but no jobId in the response.'
            };
        } catch (error: unknown) {
            PublishHelpers.handleBrickPublishStatusUpdate(brick);

            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    static cancelPublishJob = async (jobId: string, publishId: string): Promise<void> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.patch(process.env.APP_V2_API_HOST + `bricks/output/${jobId}/cancel`, undefined, {
                params: { campaignId, publishId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            if (response?.status === 200) {
                SnackbarUtils.info(Translation.get('output.cancel.successfulCancel', 'bricks'));
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                SnackbarUtils.error(Translation.get('output.cancel.failedCancel', 'bricks'));
            }
        }
    };
}
