import { Brick, BrickAssetSet, BrickMetaAd, BrickMetaAdSet, BrickMetaCampaign, BrickSingleAsset } from '../types/brick.type';
import { CustomTab, UnifiedTab } from '../types/bricksComponentStore.type';

type BrickSubTypeMap = {
    asset_set: BrickAssetSet;
    single_asset: BrickSingleAsset;
    meta_ad: BrickMetaAd;
    meta_adset: BrickMetaAdSet;
    meta_campaign: BrickMetaCampaign;
};

/**
 * Guards for the different brick types. We use this to make sure that a specific brick has its own data object.
 * @example (Guards.isBrickOfType(brick, 'asset_set')) // returns true if brick is an asset set and will give the brick type BrickAssetSet
 */
class Guards {
    static isBrickOfType<T extends keyof BrickSubTypeMap>(brick: Brick, subType: T): brick is BrickSubTypeMap[T] {
        return brick.subType === subType;
    }

    static isCustomTab = (tab: UnifiedTab): tab is CustomTab => {
        return 'customTab' in tab && tab.customTab;
    };
}

export default Guards;
