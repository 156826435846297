import EditorData from 'components/editor-data/EditorData';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import { Brick } from '../types/brick.type';
import { BricksComponentStore } from '../types/bricksComponentStore.type';
import { JobStatus } from '../types/publish.type';
import { BrickPublishJob } from '../types/brick-publish.type';
import BrickDataService from '../services/brick-data.service';
import { BrickPublishJobData } from '../hooks/useBricksPublish';
import BricksComponentStoreHelper from './bricks-component-store.helper';

class PublishHelpers {
    /**
     * Handles the tasks that need to be done after a publish status is changed
     * @param bricks The bricks to update
     * @param status The new status for these bricks
     * @returns
     */
    static handleBrickPublishStatusUpdate = (bricks: Brick | Brick[]): void => {
        if (!bricks) return;

        PublishHelpers.updateExecutionPanelBricks(bricks, 'add');
    };

    static handleAddBricksPublishJob = (bricks: Brick | Brick[], jobId: string, publishId: string, publishProfile = 'default'): BrickPublishJob => {
        if (!Array.isArray(bricks)) bricks = [bricks];

        const createdAt = new Date().toISOString();
        const publishJob: BrickPublishJob = {
            createdAt,
            status: JobStatus.WAITING,
            jobToken: jobId,
            publishId,
            failedTasks: 0,
            finishedTasks: 0,
            totalTasks: 0
        };

        bricks.forEach((brick) => {
            const publishJobs = brick.publish && brick.publish[publishProfile] ? brick.publish[publishProfile] : [];
            publishJobs.unshift(publishJob);
            BricksComponentStoreHelper.setBrickModel(brick.id, `publish.${publishProfile}`, publishJobs);
        });

        return publishJob;
    };

    /**
     * Updates the bricks in the execution panel. Get the current array of brick ids and adds the new brick ids to it.
     * @param bricks The bricks to add to the execution panel
     */
    static updateExecutionPanelBricks = (bricks: Brick | Brick[] = [], action: 'add' | 'remove'): void => {
        if (!Array.isArray(bricks)) bricks = [bricks];

        const currentBrickIds = (ComponentStore.get('Bricks') as BricksComponentStore).publishExecutionPanel.brickIds || [];

        if (action === 'add') {
            const newBrickIds = [...new Set([...currentBrickIds, ...bricks.map((brick) => brick.id)])];
            ComponentStore.setModel('Bricks', 'publishExecutionPanel', { brickIds: newBrickIds, open: true });
        } else {
            const newBrickIds = currentBrickIds.filter((brickId) => !(bricks as Brick[]).map((brick) => brick.id).includes(brickId));
            ComponentStore.setModel('Bricks', 'publishExecutionPanel', { brickIds: newBrickIds, open: true });
        }
    };

    static getPublishStatus(
        publishJobs?: BrickPublishJobData[],
        validationErrors?: Array<unknown>
    ): 'readyToPublish' | 'success' | 'publishing' | 'error' | 'info' | 'blocking' {
        // if all the publisjobs are finished return success
        // if all the publisjobs are waiting return publishing
        // if all the publisjobs are working return publishing
        // if all the publisjobs are failed return error
        // if there is a mix of statuses return info;

        const jobStatusses = publishJobs?.map((job) => job.status);
        const uniqueStatuses = Array.from(new Set(jobStatusses));

        const status = (() => {
            if (uniqueStatuses.length === 1) {
                if (uniqueStatuses[0] === JobStatus.FINISHED) {
                    // if one of the publish jobs has errors return info
                    if (publishJobs?.some((job) => job.errors?.length)) return 'info';

                    return 'success';
                }
                if (uniqueStatuses[0] === JobStatus.WAITING) return 'publishing';
                if (uniqueStatuses[0] === JobStatus.WORKING) return 'publishing';
                if (uniqueStatuses[0] === JobStatus.FAILED) {
                    // if one of the publish jobs has products return info
                    if (publishJobs?.some((job) => job.products?.length)) return 'info';

                    return 'error';
                }
            }
        })();

        if (status) return status;

        if (validationErrors && validationErrors.length > 0) return 'blocking';
        if (!publishJobs) return 'readyToPublish';
        if (publishJobs.length === 0) return 'readyToPublish';

        return 'info';
    }

    /**
     * Check if any bricks are publishing
     * @param bricks
     */
    static checkBricksPublishStatus(): void {
        const publishingBricks: Brick[] = [];
        BrickDataService.getPublishingBricks().then((bricks: Brick[] | undefined) => {
            if (!bricks || !bricks.length) return;

            Object.values(bricks).forEach((brick: Brick) => {
                if (brick.publish && brick.publish.default && brick.publish.default.length && brick.publish.default[0].status === JobStatus.WORKING)
                    publishingBricks.push(brick);
            });

            if (publishingBricks.length) this.updateExecutionPanelBricks(publishingBricks, 'add');
        });
    }
}

export default PublishHelpers;
