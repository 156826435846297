import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import BrickHelpers from '../helpers/brick.helpers';
import { BrickPublishJob } from '../types/brick-publish.type';
import { PublishJobs } from '../types/bricksComponentStore.type';

interface useBricksPublishReturn {
    publishJob?: BrickPublishJob;
}

export interface BrickPublishJobData extends BrickPublishJob {
    brickId: string;
}

interface useBricksPublishReturn {
    publishJobs?: BrickPublishJobData[];
}

/**
 * A react hook to get the publish job data for a brick
 *
 * This hook checks if there is a publish job running for the brick. If there is, it returns the job data from the componentstore where the
 * temporary data is stored. If there is no job running, it returns an empty object.
 *
 * If there is a publish job but it is finished we return the job data from the brick data itself. As we store the results of a publish job permanently in a brick.
 *
 * @param brickId the brick to get the publish job data for
 * @param selectedPublishProfile the id of the publish profile to get the data for (optional) if not provided, the default profile is returned
 * @param selectedJobId the id of the job to get the data for (optional) if not provided, the latest job is returned
 */
const useBricksPublish = (brickIds: string[], selectedJobId?: string, selectedPublishProfile?: string): useBricksPublishReturn => {
    // set publish profile key that we want to publish. This is the default publish profile if no profile is selected
    const publishProfileKey = (() => {
        if (selectedPublishProfile) return selectedPublishProfile;

        return 'default';
    })();

    const { publish } = useComponentStore<{ publish: PublishJobs }>('Bricks', {
        fields: {
            publish: `publish`
        }
    });

    if (!brickIds.length) return {};
    if (selectedJobId === '') return {};

    // if we want to get the last publish job, we set the selectedJobId to undefined
    // this is because the publish jobs of multiple bricks can be different so for each brick we need to get the last publish job
    if (selectedJobId === 'last-publish') selectedJobId = undefined;

    const publishJobs = brickIds
        .map((brickId) => {
            const brickData = BrickHelpers.getBrickById(brickId);

            if (!brickData) return null;
            if (!brickData.publish) return null;

            // search for the publish jobs with the specified profile in the brick data
            // we use this data if the status of the job is finished
            const publishJobs: BrickPublishJob[] = brickData.publish[publishProfileKey];
            if (!publishJobs) return null;

            const publishJob = (() => {
                if (selectedJobId) return publishJobs.find((item) => item.jobToken === selectedJobId);

                //TODO: THIS IS INCORRECT WE NEED TO GET THE LAST STARTED JOB INSTEAD OF THE FIRST IN THE ARRAY
                return publishJobs[0];
            })();

            if (!publishJob) return null;

            if (publishJob.status === 2) return { brickId, ...publishJob };

            // if there is a publish job running, we get the data from the componentstore
            const tempPublishJobData = (() => {
                if (!publishJob.jobToken) return null;

                const csPublishJob = publish[publishJob.jobToken];

                if (!csPublishJob) return null;

                return csPublishJob.bricks[brickId];
            })();

            const publishJobData = tempPublishJobData || publishJob;

            if (!publishJobData) return null;

            return {
                brickId,
                ...publishJobData
            };
        })
        .filter((job) => job) as BrickPublishJobData[];

    return { publishJobs };
};

export { useBricksPublish };
