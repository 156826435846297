import * as Sentry from '@sentry/react';
import axios from 'axios';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import User from 'components/data/User';

interface ZipMakerResponse {
    success: number;
    url: string;
    path: string;
}

interface ArchiveResponse {
    resources: {
        archive: string;
    };
}

export default class DownloadService {
    static downloadAssets = async (urls: string[]): Promise<string | undefined> => {
        try {
            const response = await axios.post(
                process.env.APP_MEDIA_URL + 'media/zipMaker',
                { urls },
                {
                    headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
                }
            );
            const data = response.data as ZipMakerResponse;

            // Download the assets
            window.location.href = data.url;

            return data.url;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to make a zip and download');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static downloadZip = async (files: any): Promise<string | undefined> => {
        try {
            const response = await axios.post(
                process.env.APP_MEDIA_URL + 'integrations/publish/execute',
                {
                    taskId: '1',
                    jobId: '1',
                    campaignId: '1',
                    service: 'files',
                    version: 1,
                    resources: {
                        archive: 'archive'
                    },
                    type: 'archive',
                    content: {
                        files
                    }
                },
                {
                    headers: { Authorization: `Bearer ${User.get('apiToken')}` }
                }
            );
            const data = response.data as ArchiveResponse;

            // Download the assets
            window.location.href = data.resources.archive;

            return data.resources.archive;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to make a zip and download');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };
}
