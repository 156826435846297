import ImageFileService from 'services/image-file/image.service';
import { FileExt } from '../types/brick.type';
import { AssetHelpers } from './asset.helpers';

/**
 * Handles the validation of asset presets.
 */
class AssetPresetValidator {
    /**
     * Validates the aspect ratio based on the provided restricted aspect ratio.
     * If the aspectRatioType is minAspectRatio, the function will validate if the aspect ratio is greater than or equal to the restricted aspect ratio.
     * If the aspectRatioType is maxAspectRatio, the function will validate if the aspect ratio is less than or equal to the restricted aspect ratio.
     * @param aspectRatio Current aspect ratio to validate.
     * @param restrictedAspectRatio Restricted aspect ratio to validate against.
     * @param aspectRatioType Aspect ratio type to validate.
     * @returns True if the aspect ratio is valid, false otherwise.
     */
    static isAspectRatioValid(aspectRatioType: 'minAspectRatio' | 'maxAspectRatio', aspectRatio?: string, restrictedAspectRatio?: string): boolean {
        if (!aspectRatio || !restrictedAspectRatio) return true; // If the aspect ratio or the restricted aspect ratio is not provided, return true.

        const aspectRatioValue = AssetHelpers.convertAspectRatio(aspectRatio); // Convert the aspect ratio to a number.
        const restrictionAspectRatio = AssetHelpers.convertAspectRatio(restrictedAspectRatio); // Convert the restricted aspect ratio to a number.

        return aspectRatioType === 'minAspectRatio' ? aspectRatioValue >= restrictionAspectRatio : aspectRatioValue <= restrictionAspectRatio; // Validate the aspect ratio based on the aspect ratio type.
    }

    /**
     * Validates the file extension based on the provided restricted file extension.
     * @param fileExtension Current file extension to validate.
     * @param restrictedFileExtensions Restricted file extensions to validate against.
     * @returns True if the file extension is valid, false otherwise.
     */
    static isFileExtensionValid(fileExtension: FileExt, restrictedFileExtensions: FileExt[]): boolean {
        return restrictedFileExtensions.includes(fileExtension); // Checks if the current file extension exists in the restricted file extensions.
    }

    /**
     * Validates the file size based on the provided maximum file size.
     * @param maxSizeKb Maximum file size in kilobytes.
     * @param fileSizeInBytes File size in bytes to validate.
     * @returns True if the file size is valid, false otherwise.
     */
    static isFileSizeValid(maxSizeKb: number, fileSizeInBytes?: number): boolean {
        if (fileSizeInBytes === undefined) return false; // If the file size is not provided, return false.
        const fileSizeKb = ImageFileService.convertBytesToKiloBytes(fileSizeInBytes); // Convert the file size to kilobytes.

        return fileSizeKb <= maxSizeKb;
    }

    /**
     * Validates the duration based on the provided maximum duration.
     * @param maxDuration Maximum duration in seconds.
     * @param duration Duration in seconds to validate.
     * @returns True if the duration is valid, false otherwise.
     */
    static isMaxDurationValid(maxDuration: number, duration?: number): boolean {
        return !!duration && duration <= maxDuration;
    }

    /**
     * Validates the duration based on the provided minimum duration.
     * @param minDuration Minimum duration in seconds.
     * @param duration Duration in seconds to validate.
     * @returns True if the duration is valid, false otherwise.
     */
    static isMinDurationValid(minDuration: number, duration?: number): boolean {
        return !!duration && duration >= minDuration;
    }
}

export { AssetPresetValidator };
