import moment from 'moment';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import EditorData from 'components/editor-data/EditorData';
import { Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import PublishService, { PublishResponse } from '../services/publish.service';
import { DownloadDetails } from '../types/bricksComponentStore.type';
class DownloadHelpers {
    static async downloadBrickStructure(brick: Brick): Promise<PublishResponse | undefined> {
        const brickStructure = BrickHelpers.getBrickStructure(brick);

        return await this.downloadSelectedBricks(brickStructure);
    }

    static async downloadSelectedBricks(bricks: Brick[]): Promise<PublishResponse | undefined> {
        const archiveName = this.getArchiveName();
        const result = await PublishService.downloadBricks(bricks, archiveName);
        const id = result?.publishId;

        if (id) this.setDownloadStatus(bricks, id, archiveName);

        return result;
    }

    private static setDownloadStatus = (bricks: Brick[], publishId: string, archiveName: string): void => {
        const downloadStatus: DownloadDetails = {
            bricksCount: bricks.length,
            archiveName
        };

        ComponentStoreHelpers.setMultiModels('Bricks', [
            ['publishExecutionPanel.open', true],
            [`download.${publishId}`, downloadStatus]
        ]);
    };

    private static getArchiveName = () => {
        const title: string = EditorData.get('title');
        const market = EditorData.get('market');
        const parsedTitle = title.replaceAll(' ', '');
        const date = moment().format('DD-MM-YYYY-HH:mm:ss');
        const name = `${parsedTitle}-${market}-${date}`;
        return name;
    };
}

export default DownloadHelpers;
