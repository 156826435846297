import { useSelector } from 'react-redux';
import Store from 'types/store.type';
import { isEqual } from 'lodash';
import { BRICK_ID_PREFIX, MODEL_BRICKS, MODEL_TEMP_BRICK } from 'components/bricks/constants';
import { Brick } from 'components/bricks/types/brick.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';

interface UseBrick {
    brick?: Brick;
    brickChildren: Brick[];
}

interface ComponentStoreProps {
    brick?: Brick;
    activeItemId?: string;
}

/**
 * Hook to listen to a specific brick based on the provided id.
 * @param brickId Optional brick id to listen on.
 * @returns an object of UseBrick.
 */
const useBrick = (brickId?: string, isTemporaryBrick?: boolean): UseBrick => {
    const activeItemId = brickId || useComponentStore<ComponentStoreProps>('Bricks', { fields: { activeItemId: 'activeItemId' } }).activeItemId;

    // Get the active brick model based on the active item id
    const brickModel = isTemporaryBrick ? MODEL_TEMP_BRICK : `${MODEL_BRICKS}.${BRICK_ID_PREFIX}${activeItemId}`;

    const { brick } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { brick: brickModel }
    });

    const brickChildren = useSelector((state: Store) => {
        const bricks = state.componentStore.Bricks.bricks;
        const parentId = brick?.id;

        if (!bricks || !parentId) return []; // Return empty array if no bricks are found.

        return BrickHelpers.getChildrenFromBricks(bricks, parentId); // Get the children of the active brick.
    }, isEqual);

    return { brick, brickChildren };
};

export default useBrick;
