import { ExpandedState } from '@tanstack/react-table';
import ComponentStore from 'components/data/ComponentStore';
import { BrickViewState } from './brick-view-state.helpers';
import { Brick } from '../types/brick.type';

class BrickExpandedRowsHelper {
    static setExpandedBrick = (isExpanded: boolean, brickId?: string): void => {
        if (!brickId) return;

        ComponentStore.setModel('Bricks', `expandedBricks.${brickId}`, isExpanded); // Set the expanded state of the brick to the component store.
        const expandedBricks = BrickViewState.get('expandedBricks');
        BrickViewState.set('expandedBricks', { ...expandedBricks, [brickId]: isExpanded }); // Set the expanded state of the brick to the local store.
    };

    static removeExpandedBrick = (brickId?: string): void => {
        if (!brickId) return;

        ComponentStore.removeItem('Bricks', `expandedBricks.${brickId}`); // Remove the expanded state of the brick from the component store.
        const expandedBricks = BrickViewState.get('expandedBricks');

        if (!expandedBricks) return;

        const { [brickId]: _, ...updatedExpandedBricks } = expandedBricks;
        BrickViewState.set('expandedBricks', updatedExpandedBricks); // Remove the expanded state of the brick from the local store.
    };

    static expandedAllBricks = (bricks: Brick[]): ExpandedState => {
        const initialValue: ExpandedState = {};
        return bricks.reduce((acc, brick) => {
            acc[brick.id] = true;
            return acc;
        }, initialValue);
    };
}

export default BrickExpandedRowsHelper;
