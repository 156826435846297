import ViewState from 'components/data/ViewState';
import EditorData from 'components/editor-data/EditorData';

/**
 * Bricks view state
 * wrapper around ViewState to make sure that the view state is saved per brick per use instead of per user
 */
class BrickViewState {
    static get(action: string) {
        return ViewState.get(`bricks_${EditorData.getId()}`, action);
    }

    static set(action: string, value: any) {
        return ViewState.set(`bricks_${EditorData.getId()}`, action, value);
    }
}

export { BrickViewState };
