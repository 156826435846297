import { PlacementsSection } from 'components/bricks/types/placement.type';

class GeneralHelpers {
    /**
     * Reorders items in an array based on a key and identifier
     * @example GeneralHelpers.reorderItems(items, 'afterColumn', 'name') -> Reorders the array and makes sure that the object with afterColumn: 'date' will be placed
     * after the object with name: 'date'
     * @param items The array of items to reorder
     * @param key The key to check for in the list of items. If it has this key, it will reorder this object (for example, 'afterColumn' or 'afterTab')
     * @param identifier The identifier of the item (for example, 'id' or 'name')
     * @returns The rerodered array of items
     */
    static reorderItems = <T>(items: T[], key: string, identifier: keyof T): T[] => {
        const keyToIndex: Record<string, number> = {};

        items.forEach((item, index) => {
            keyToIndex[item[identifier] as string] = index;
        });

        const reorderedItems: T[] = [];

        items.forEach((item) => {
            if (item && typeof item === 'object' && key in item) {
                const afterItemIndex = keyToIndex[item[key] as string];
                if (afterItemIndex !== undefined) {
                    reorderedItems.splice(afterItemIndex + 1, 0, item);
                }
            } else {
                reorderedItems.push(item);
            }
        });

        return reorderedItems;
    };

    /**
     * Extracts placement keys from a list of PlacementsSection
     * @param placementsSection The placementsSection of placements with children
     * @returns An array of placement keys
     */
    static extractPlacementKeys = (placementsSection: PlacementsSection[]): string[] => {
        return placementsSection.reduce((acc: string[], section: PlacementsSection) => {
            const placementKeys = section.children.map((child) => child.key);
            return [...acc, ...placementKeys];
        }, []);
    };
}

export default GeneralHelpers;
