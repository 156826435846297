import { Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';

class BrickFeedHelpers {
    /**
     * Gets the dataset id of parent feed brick
     * @param brick
     * @returns dataset id
     */
    static getDataSetIdOfParentFeedBrick = (brick: Brick): string | undefined => {
        const parentBrick = BrickHelpers.getBrickById(brick.parentId);

        if (!parentBrick?.parentId) return parentBrick?.data?.dataset?.datasetId;

        return this.getDataSetIdOfParentFeedBrick(parentBrick);
    };
}

export default BrickFeedHelpers;
