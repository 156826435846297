import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import { Expression, RegexHelpers } from 'helpers/regex.helpers';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import { InputValidator, Validation, ValidationObject, ValidationResults } from '../types/validator.type';
import { Brick, BrickSubType } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { AD_SETUP, PARENT_SETTINGS_PLACEMENT_TARGETING } from '../constants';

// TODO Refactor

export interface BrickParent extends Brick {
    parent?: BrickParent;
}

class ValidateHelpers {
    static validate = (brick: Brick): any => {
        if (brick.subItems && brick.subItems.length) return brick.subItems?.forEach((subItem) => ValidateHelpers.validate(subItem));
        const oldErrors = ComponentStoreHelpers.get('Bricks').validationErrors || {};
        const validationResults = ValidateHelpers.getErrors(brick, oldErrors);

        ComponentStore.setModel('Bricks', 'validationErrors', validationResults);
    };

    /**
     * Getting the validation errors for the brick and its children
     * @param brick
     * @returns errors for the validations of the brick and its children
     */
    private static getErrors = (brick: Brick, validationResults: ValidationResults): ValidationResults => {
        try {
            const validators: ValidationObject = BrickHelpers.getBrickData(brick.subType, 'validators');
            const currentBrickErrors: Validation[] = [];

            if (!validators) return validationResults;

            // brick doesnt have data we are setting an empty data to support the validators
            if (!brick.data) {
                brick.data = {
                    // we add an empty settings object because every brick has a settings tab with the key settings
                    settings: {}
                };
            }

            const children = BrickHelpers.getChildrenOfBrick(brick);

            // Getting the errors for the children of the brick
            if (children && children.length) {
                children.forEach((child) => {
                    validationResults = ValidateHelpers.getErrors(child as Brick, validationResults);
                });
            }

            const parent = this.getBrickParent(brick);

            // Going through the validations and checks them
            validators.validators.forEach((validator) => {
                try {
                    if (eval(validator.condition)) {
                        currentBrickErrors.push({
                            message: validator.message,
                            inputIdentifiers: validator.inputIdentifiers,
                            tabKey: validator.tabKey,
                            severity: validator.severity
                        });
                    }
                } catch (err: any) {
                    /* empty */
                }
            });

            // Check if the current brick has errors and put them into the error object
            if (currentBrickErrors.length) validationResults = { ...validationResults, [brick.id]: currentBrickErrors };
            // Remove the brick from the errors object
            else delete validationResults[brick.id];

            return validationResults;
        } catch (err) {
            return validationResults;
        }
    };

    private static getBrickParent = (brick: Brick): BrickParent | undefined => {
        if (!brick.parentId) return;

        const parent: BrickParent | undefined = BrickHelpers.getBrickById(brick.parentId);

        if (parent) {
            parent.parent = this.getBrickParent(parent);
        }

        return parent;
    };

    /**
     * Checks if they are errors for the brick
     * @param brickId
     */
    public static isBrickWithValidationErrors = (brickId: string): boolean => {
        const errors = ComponentStoreHelpers.get('Bricks').validationErrors || {};

        if (errors[brickId] && errors[brickId].length) return true;

        return false;
    };

    /**
     * Gets validation errors for brick
     * @param brickId
     * @returns validation errros
     */
    public static getValidationErrors = (brickId: string): Validation[] => {
        const allValidationErrors: string[] = ComponentStoreHelpers.get('Bricks').validationErrors || {};
        if (allValidationErrors[brickId] && allValidationErrors[brickId].length) return allValidationErrors[brickId];
        return [];
    };

    /**
     * Get input validators by identifier
     * @param brickType
     * @param identifier
     * @returns Input validators
     */
    public static getInputValidators = (brickType: BrickSubType, identifier: string): InputValidator[] => {
        const validators: ValidationObject = BrickHelpers.getBrickData(brickType, 'validators');
        let inputValidatorsByIdentifier: InputValidator[] = [];
        for (const validation of validators.validators) {
            if (validation.inputIdentifiers.includes(identifier) && validation.inputValidator)
                inputValidatorsByIdentifier = [...inputValidatorsByIdentifier, ...validation.inputValidator];
        }
        return inputValidatorsByIdentifier;
    };

    /**
     * Gets errors related to the input
     * @param brickId
     * @param identifier of the input
     * @returns errors
     */
    public static getActiveErrorsForInput = (brickId: string, identifier: string): Validation[] => {
        const allErrorsForBrick = this.getValidationErrors(brickId);
        const errorsForInput: Validation[] = [];
        for (const error of allErrorsForBrick) {
            if (error.inputIdentifiers.includes(identifier)) errorsForInput.push(error);
        }
        return errorsForInput;
    };

    /**
     * Generates a condition string for validating the placement of creatives.
     *
     * @param placementKey - The placement key
     * @param frameIndex - The frameIndex of the ad frame (default is 0)
     * @returns The condition string for validation
     */
    public static generatePlacementValidationCondition(adSetupType: AdSetupFrameType, placementKey: string, frameIndex = 0): string {
        return `(!${PARENT_SETTINGS_PLACEMENT_TARGETING}.length || ${PARENT_SETTINGS_PLACEMENT_TARGETING}.includes("${placementKey}")) && ${AD_SETUP}.type === '${adSetupType}' && ${AD_SETUP}.items?.[${frameIndex}] && (!${AD_SETUP}.items[${frameIndex}].creativeIds || !${AD_SETUP}.items[${frameIndex}].creativeIds?.length || !${AD_SETUP}.items[${frameIndex}].placements["${placementKey}"])`;
    }

    /**
     * Creates a condition to check if creatives exist in a specific frame.
     *
     * @param adSetupType - 'single' or 'multi' ad setup type
     * @param frameIndex - Frame index (default is 0)
     * @returns Condition string for the frame
     */
    public static generateFrameCreativeValidator(adSetupType: AdSetupFrameType, frameIndex = 0): string {
        return `${AD_SETUP}.type === '${adSetupType}' && ${AD_SETUP}.items?.[${frameIndex}] && !${AD_SETUP}.items[${frameIndex}].creativeIds?.length`;
    }

    /**
     * Checks if a required field exists in a specific frame.
     *
     * @param adSetupType - 'single' or 'multi' ad setup type
     * @param frameIndex - Frame index
     * @param inputKey - Name of the input to validate
     * @returns Condition string for the required input
     */
    public static generateRequiredFieldItemValidator(adSetupType: AdSetupFrameType, frameIndex: number, inputKey: string): string {
        return `${AD_SETUP}.type === '${adSetupType}' && ${AD_SETUP}.items?.[${frameIndex}] && (!${AD_SETUP}.items[${frameIndex}].${inputKey} || ${AD_SETUP}.items[${frameIndex}].${inputKey}.length < 1)`;
    }

    /**
     * Retrieves a regex match expression based on the regex name.
     * @param regexName - Name of the regular expression
     * @returns - A string that evaluates to a regex match condition
     * @example ValidateHelpers.getRegexMatch('onlyNumbers') => 'match(/^[0-9]+$/)'
     */
    public static getRegexMatch(regexName: Expression): string {
        const regex = RegexHelpers.getRegexExpression(regexName);
        return `match(${regex})`;
    }
}

export default ValidateHelpers;
