import ComponentStoreHelpers from 'components/data/ComponentStore';
import { Brick, BrickSetup, BrickSetupTab } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { UnifiedTab } from '../types/bricksComponentStore.type';
import MultiSocialHelpers from './multi-social.helpers';
import ValidateHelpers from './validate.helpers';

interface BrickSlidePanelOptions {
    showComments?: boolean;
    activeTabKey?: BrickSetupTab['key'];
}

// Get the initial tab for the selected brick
const getInitialActiveTab = (brick: Brick, tabKey?: BrickSetupTab['key']): BrickSetupTab | undefined => {
    const brickSetup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');

    if (tabKey) {
        return brickSetup.tabs.find((tab) => tab.key === tabKey);
    }

    // First check if the brick has a dynamic tab (so a tab based on some function checks)
    if (brickSetup.dynamicTab) {
        const dynamicTab = brickSetup.dynamicTab(brick);
        if (dynamicTab) {
            const foundTab = brickSetup.tabs.find((tab) => tab.key === dynamicTab);
            if (foundTab) return foundTab;
        }
    }

    if (brickSetup.defaultTab) {
        return brickSetup.tabs.find((tab) => tab.key === brickSetup.defaultTab);
    }

    return;
};

const defaultOptions: BrickSlidePanelOptions = {
    showComments: false
};

const openSlidePanel = (brick: Brick, options: BrickSlidePanelOptions = defaultOptions): void => {
    // Get the default tab for the selected brick
    const { showComments, activeTabKey } = options;
    const initialActiveTab = getInitialActiveTab(brick, activeTabKey);

    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['slidePanelOpen', true],
        ['slidePanelCommentsOpen', showComments],
        ['activeItemId', brick.id],
        ['activeTab', initialActiveTab]
    ]);
};

const closeSlidePanel = (): void => {
    ComponentStoreHelpers.setModel('Bricks', 'slidePanelOpen', false);

    // Timeout to make sure the slide panel closes quickly
    setTimeout(() => {
        const activeItemId = ComponentStoreHelpers.get('Bricks')?.activeItemId;

        ComponentStoreHelpers.removeItem('Bricks', 'activeItemId');
        ComponentStoreHelpers.removeItem('Bricks', 'activeTab');
        ComponentStoreHelpers.remove('CreativeEditorV2');
        ComponentStoreHelpers.remove('CreativeOverview');

        MultiSocialHelpers.handleOriginalBrick();

        // TODO is this needed (the validation part)?
        const newBrick = BrickHelpers.getBrickById(activeItemId);
        if (newBrick) ValidateHelpers.validate(newBrick);
    }, 500);
};

const setActiveItem = (brickId?: string, activeTab?: UnifiedTab): void => {
    const brick = BrickHelpers.getBrickById(brickId);

    if (!brick) return;

    const brickSetup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
    const hasTab = brickSetup.tabs.find((tab) => tab.key === activeTab?.key);
    const intialActiveTab = getInitialActiveTab(brick);

    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['activeItemId', brick.id],
        // if the tab that is currently selected exists in the brick that the user wants to open use that one otherwise use the default tab of the brick
        ['activeTab', hasTab ? hasTab : intialActiveTab]
    ]);
};

export { openSlidePanel, setActiveItem, closeSlidePanel };
