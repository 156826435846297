import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { BrickTree } from '../types/brick.type';
import { BricksObject } from '../types/bricksComponentStore.type';
import BrickHelpers from './brick.helpers';

class TreeHelpers {
    /**
     * This function is responsible in order to convert the bricks object into a tree structure.
     * @param bricksObject A list of all bricks in a form of an object.
     */
    static createTreeV2 = (bricksObject?: BricksObject): BrickTree[] => {
        // First pass: build all nodes
        const brickMap = new Map<string, BrickTree>();
        for (const id in bricksObject) {
            const brick = bricksObject[id];
            const treeNode: BrickTree = { id: brick.id, children: [], order: brick.order };
            brickMap.set(id, treeNode);
        }

        // Second pass: link parents and children
        const roots: BrickTree[] = [];
        for (const id in bricksObject) {
            const brick = bricksObject[id];
            const treeNode = brickMap.get(id);

            if (brick.parentId) {
                const parentBrickId = BrickHelpers.getBrickIdPrefix(brick.parentId);
                const parentTreeNode = brickMap.get(parentBrickId);

                if (!parentTreeNode && treeNode) {
                    roots.push(treeNode); // If the parent does not exist, the node is a root node, this is used when a partial tree is passed.
                }

                if (parentTreeNode && treeNode) {
                    parentTreeNode.children.push(treeNode); // Add child to parent.
                }
            } else {
                if (treeNode) roots.push(treeNode); // If the brick does not have a parentId, it is a root node.
            }
        }

        // Return a new sorted tree
        return roots
            .map(TreeHelpers.sortChildren) // Recursively sort the children
            .sort((a, b) => (a.order ?? 1) - (b.order ?? 1)); // Sort the root nodes
    };

    /**
     * Sort the children of each node based on the order position and return a new sorted node
     */
    static sortChildren = (node: BrickTree): BrickTree => {
        const sortedChildren = node.children
            .map(TreeHelpers.sortChildren) // Recursively sort the children
            .sort((a, b) => (a.order ?? 1) - (b.order ?? 1));

        return { ...node, children: sortedChildren };
    };

    private static getCreative = async (creative) => {
        try {
            if (creative) {
                return await CreativeV2Helpers.enrichCreative(creative);
            }
        } catch (error) {
            return;
        }
    };

    /**
     * This function is responsible for adding creatives to the tree structure.
     */
    static addCreativesToTree = async (brickTree: BrickTree[]): Promise<BrickTree[]> => {
        const clonedBrickTree = [...brickTree];

        for (const brickTreeItem of clonedBrickTree) {
            const brick = BrickHelpers.getBrickById(brickTreeItem.id);

            if (!brick) continue;

            if (brick.subType.startsWith('creative') && brick.data && brick.data.creatives && brick.data.creatives.length) {
                const creative = brick.data.creatives[0];
                const enrichedCreative = await this.getCreative(creative);
                const activeFormats: string[] = enrichedCreative?.data?.settings?.activeFormats;

                if (activeFormats) {
                    for (const format of activeFormats) {
                        const creativeBrickTreeItem: BrickTree = {
                            type: 'creative',
                            id: brick.id + '-' + format,
                            children: []
                        };

                        brickTreeItem.children.push(creativeBrickTreeItem);
                    }
                }
            }
        }

        return clonedBrickTree;
    };
}

export default TreeHelpers;
