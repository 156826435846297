import { AssetMetadata, AssetMetadataFeedback, ValidationResults } from 'components/bricks/helpers/asset.helpers';
import { AssetRestrictionsKeys, AssetRestrictions } from 'components/bricks/types/preset';
import restrictionConditionMap from '../data/restrictions-condition-map';

/**
 * This helper class handles the validation of the asset metadata based on the provided restrictions.
 */
class AssetRestrictionsHelper {
    /**
     * This function is used to get the keys of the restrictions to be able to iterate over them.
     * @param restrictions Asset restrictions to get the keys from.
     * @returns Asset restrictions keys.
     */
    private static getRestrictionsKeys(restrictions: AssetRestrictions): AssetRestrictionsKeys[] {
        return Object.keys(restrictions) as AssetRestrictionsKeys[];
    }

    /**
     * Filters the restrictions feedback to remove the undefined values.
     * @param restrictionsFeedback Restrictions feedback to filter.
     * @returns A list of restrictions feedback without the undefined values.
     */
    private static filterAssetRestrictions(restrictionsFeedback: (AssetMetadataFeedback | undefined)[]): AssetMetadataFeedback[] {
        return restrictionsFeedback.filter((feedback) => feedback !== undefined) as AssetMetadataFeedback[];
    }

    /**
     * Validates the asset metadata based on the provided restriction.
     * @param assetMetadata Asset metadata to validate.
     * @param restrictions Asset restrictions to validate against.
     * @param key Restriction key to validate, this is used to get the current restriction and the validation function.
     */
    private static validateAssetMetadataByKey(
        assetMetadata: AssetMetadata,
        restrictions: AssetRestrictions,
        key: AssetRestrictionsKeys
    ): AssetMetadataFeedback | undefined {
        const restriction = restrictions[key];
        let validationFail = false; // Default to false, this means that the asset data is passed the requirement.
        const validateAssetData = restrictionConditionMap[key]; // Get the validation function based on the restriction type.

        if (!validateAssetData) return; // If the validation function does not exist, return undefined.

        validationFail = validateAssetData(assetMetadata, restriction); // Validate the asset data based on the current restriction.

        if (validationFail) {
            return { type: key }; // If the validation fails, return the type of the restriction, this means that the asset data does not pass the requirement.
        }
    }

    /**
     * Gets the restrictions feedback based on what restrictions were not met.
     * This functions can be used the get the feedback for the restrictions and the recommendations.
     * @param assetMetadata Asset metadata to validate.
     * @param restrictions Asset restrictions to validate against.
     * @returns Asset metadata feedback based on the provided restrictions.
     */
    private static getAssetRestrictionsFeedback(assetMetadata: AssetMetadata, restrictions: AssetRestrictions): AssetMetadataFeedback[] {
        const restrictionKeys = this.getRestrictionsKeys(restrictions); // Get the restriction keys.
        const restrictionsFeedback = restrictionKeys.map<AssetMetadataFeedback | undefined>((key) =>
            this.validateAssetMetadataByKey(assetMetadata, restrictions, key)
        ); // Get the feedback for each restriction.

        return this.filterAssetRestrictions(restrictionsFeedback); // Filter the feedback to remove the undefined values.
    }

    /**
     * Validates the asset metadata based on the provided restrictions and recommendations.
     * @param data Asset metadata to validate.
     * @param restrictions Asset restrictions to validate against.
     * @param recommendations Asset recommendations to validate against.
     */
    static validateAssetMetadataByRestrictions(data: AssetMetadata, restrictions?: AssetRestrictions, recommendations?: AssetRestrictions): ValidationResults {
        const restrictionsFeedback = restrictions ? this.getAssetRestrictionsFeedback(data, restrictions) : [];
        const recommendationsFeedback = recommendations ? this.getAssetRestrictionsFeedback(data, recommendations) : [];

        return { errors: restrictionsFeedback, warnings: recommendationsFeedback };
    }
}

export default AssetRestrictionsHelper;
